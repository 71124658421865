import React, { Component } from 'react'
import {css} from 'emotion';
import { Link } from "gatsby";
import Cover from '../assets/img/car-cover.jpg';

//Components
import Header from '../components/appComponents/Header';
import Layout from "../components/ahlComponents/Layout";
import Home from '../components/partnerComponent/partnerHome';
import Options from '../components/partnerComponent/options';
import Vehicle from '../components/partnerComponent/attachVehicle';
import RentalServices from '../components/partnerComponent/rentalServices';
import Restaurants from '../components/partnerComponent/restaurants';
import Success from '../components/partnerComponent/success';
import Helmet from 'react-helmet';

/* global tw */

class partnerWithUs extends Component {
    constructor(props){
        super(props);
        this.state={
            home:true,
            options:false,
            vehicle:false,
            rentalServices:false,
            restaurants:false,
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(state){
        if(state === 'home'){
            this.setState({
                home:true,
                options:false,
                vehicle:false,
                rentalServices:false,
                restaurants:false,
            })
        }
        if(state === 'options'){
            this.setState({
                home:false,
                options:true,
                vehicle:false,
                rentalServices:false,
                restaurants:false,
            })
        }
        if(state === 'vehicle'){
            this.setState({
                home:false,
                options:false,
                vehicle:true,
                rentalServices:false,
                restaurants:false,
            })
        }
        if(state === 'rentalServices'){
            this.setState({
                home:false,
                options:false,
                vehicle:false,
                rentalServices:true,
                restaurants:false,
            })
        }
        if(state === 'restaurants'){
            this.setState({
                home:false,
                options:false,
                vehicle:false,
                rentalServices:false,
                restaurants:true,
            })
        }
    }

    render() {
        return (
            <div>
            {/* <div className={css`${tw`w-full flex hidden max-w-screen max-h-full overflow-hidden relative`}; background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%)`}>
            <Header name={this.props.userName} isLoggedIn={this.props.isUserLoggedIn} onClick={this.handleLogoClick} onMenuPress={() => { this.setState({ menuOpen: true }) }} />
            </div> */}
                <Helmet
                    // title='Hyderabad Rental Cars, Car Rental Near Me, Home to Office Transportation'
                     title = 'Zyppys - #1 Outstation Car Rental Services in India. Book Zyppys car rentals for hassle-free journeys for family trips and outings with friends!'
                    // title = "Self-Drive Car Rental In India : Outstation Cabs | Rent A Car | Car Hire Online | Outstation Taxi Booking Service | Airport Transfers | Book Hourly Rental Car - ZYPPYS"
                    meta={[
                        { name: 'description', content: 'Hiring self-drive car is in on your fingertip Zyppys is one of the best car rental services for outstation cabs, cab to airport and more. Zyppys is pocket and environment friendly.' },
                        // { name: 'description', content: 'Self Drive Car Rental In India - Outstation Cabs - Airport Transfer Services - Hourly Rental Car : Book best Self-drive car rentals online, hire Self drive cars without driver, Outstation cabs, Outstation taxi, Airport transfer services and Hourly rental cars at affordable prices at ZYPPYS in India.' },
                        { name: 'keywords', content: 'Zyypys, Car Rental, car rental near me, car rental app, car rental bangalore, car rental hyderabad, Roadtrip, Outstation Cabs, Road Travel, Chauffeur Driven Cars, Rental Cars, Cheap Rental Cars, Vacation Rentals, Outstation Rentals, Bike Rentals, Aha Taxis, Revv, Zoomcar, Avis, Hertz, Gozo Cabs, Savaari, Taxi Bazaar, Quick Ride, Car Pooling, Car sharing, Travel, Road travel, Airport Taxi, Self Drive Cars, Car Rentals, self drive car hire bangalore,self drive car rental bangalore ,self drive hire bangalore, self drive rental bangalore,self drive car hire hyderabad,self drive car rental hyderabad ,self drive hire hyderabad, self drive rental hyderabad,self drive hire cars ,self drive car rental ,self drive hire , self drive rental , car rental hyderabad, car hire hyderabad,car rental bangalore, car hire bangalore, Bangalore to Coorg car Rental, Bangalore to Mysore car Rental, Bangalore to Ooty car Rental, car rental, car hire, car rental near, outstation cabs, outstation car rental, outstation cabs one way, outstation cabs booking, car booking outstation, Bangalore to Chikmagalur cabs, outstation cabs hyderabad, outstation car rentals hyderabad, outstation cabs one way hyderabad, hyderabad to karimnagar cabs, bangalore to mysore cab, hyderabad to vijayawada cabs, hyderabad to warangal cabs,bangalore to coorg cab,bangalore to ooty cab, bangalore to nandi hills cab, cars, Bangalore to Pondicherry cabs, bangalore to tirupati cab, outstation cabs bangalore, outstation car rentals bangalore, cabs from hyderabad to srisailam, hyderabad to bangalore cab, Bangalore to Kodaikanal cabs, Bangalore to Chennai cabs, Bangalore to Wayanad cabs ' },
                        { name: "Viewport", content: "width=device-width" },
                        { property: "og:image", content: 'https://zyppysimages.s3.ap-south-1.amazonaws.com/zyplogo.png' },
                        { property: "og:image:width", content: "200" },
                        { property: "og:image:height", content: "200" },
                        { property: "og:type", content: "website" },
                        { property: "og:title", content: "Self Drive Cars & Rental Car, Car Rental, Cab on Rent" },
                        { property: "og:description", content: "Best car rental service for individual, family, corporate, self-drive cars and customize services for urban & semi-urban cities in India. Zyppys is a platform where people can compare and rent a car." },
                        // { property: "og:url", content: "https://zyppys.com/" },
                        { property: "og:site_name", content: "Zyppys" },
                        { property: "og:locale", content: "en_US" }
                    ]}
                />
            <div className={css`${tw`flex flex-row w-full h-screen`}`}>
            <div className={css`${tw`flex justify-center items-center w-full lg:w-1/2 overflow-y-scroll`}`}>
            <div className={css`${tw`absolute mt-0 mr-10 cursor-pointer`}; right:0; top:0; &:hover{  transform: scale(0.98); }`}>
            <Link 
                    to='/'
                    className={css`${tw`no-underline `}`}>
                    <img src={require('../assets/img/logo-with-text.svg')} height='100' width='100' />
                    </Link>
                </div>
            {this.state.home && <Home
                optionsClicked={()=> {this.handleClick('options')}}
                />}
            {this.state.options && <Options 
                homeClicked={()=> {this.handleClick('home')}}
                attachVehicleClicked={()=> {this.handleClick('vehicle')}} 
                rentalServicesClicked={()=> {this.handleClick('rentalServices')}} 
                restaurantsClicked={()=> {this.handleClick('restaurants')}}
                />}
            {this.state.vehicle && <Vehicle
                optionsClicked={()=> {this.handleClick('options')}}
                />}
            {this.state.rentalServices && <RentalServices
                optionsClicked={()=> {this.handleClick('options')}}
                />}
            {this.state.restaurants && <Restaurants
                optionsClicked={()=> {this.handleClick('options')}}
                />}
            </div>
            
            <div className={css`${tw`text-center content-center items-center hidden lg:flex lg:w-1/2 px-4 py-2 m-4`}; background-image: url(${Cover}); background-size: cover; background-repeat: no-repeat;`}/>
            </div>
            </div>
        )
    }
}

export default partnerWithUs;
